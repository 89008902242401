@import "layouts/index";
@import "base/index";
@import "components/index";
@import "components-page/index";
@import "pages/index";
@import "apps/index.less";
@import "global/index.less";
@import "dashboard/index.less";
@import "slick/index.less";
@import "swiper/index.less";
@import "selectbox/index.less";
@import "dark/index.less";


.ant-modal-body {
  padding-top: 18px;
}